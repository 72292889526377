export const environment = {
  production: true,
  userURL: "https://dev-api.ourlane.com/users/v1",
  analyticsURL: "https://dev-api.ourlane.com/analytics/v1",
  paymentURL: "https://dev-api.ourlane.com/payments/v1",
  staticURL: "https://dev-api.ourlane.com/configurations/v1",
  tripURL: "https://dev-api.ourlane.com/trips/v1",
  chatUrl: "https://dev-api.ourlane.com/communications/v1",
  trackURL: "https://dev-api.ourlane.com/tracking/v1",
  carURL: "https://dev-api.ourlane.com/cars/v1",
  copilotUrl: "https://dev-api.ourlane.com/copilot/v1",
  notificationURL: "https://dev-api.ourlane.com/notifications/v1",
  reportURL: "https://dev-api.ourlane.com/reports/v1",
  collectorUrl: "https://dev-api.ourlane.com/collector/v1",
  siteKey: "6LfT6N4nAAAAAGQljG3o_BcTnBpQOWmuob2YZYBi",
  socketURL: "https://dev-api.ourlane.com",
  alertUrl: "https://dev-api.ourlane.com/alerts/v1",
  mapbox: {
    accessToken:
      "pk.eyJ1IjoibW9uaWljb3BzIiwiYSI6ImNsbTdtNDZvdzAyMmYzam81b2t3YnQ3aGMifQ.x-upIVaVpO5iXWoZ2hC-yQ",
  },
  ourFleetData: {
    portalUrl: "https://dev-admin.ourlane.com",
    greyCarLocation: "https://dev-img.ourlane.com/images/map/grey.png",
    blackCarLocation: "https://dev-img.ourlane.com/images/map/black.png",
    blueCarLocation: "https://dev-img.ourlane.com/images/map/blue.svg",
    greenCarLocation: "https://dev-img.ourlane.com/images/map/green.svg",
  },
};
